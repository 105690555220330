<script setup lang="ts">
definePageMeta({
  middleware: ['hidewhenauth'],
  layout: false,
})

const { t, locale } = useI18n()
const localePath = useLocalePath()

const route = useRoute()

const redirectUrl = ref<any>(route?.query?.redirect ?? localePath('/'))

const host = await useGetHost()

const token: any = useCookie('token')

const authenticated = useAuthenticated()

const sitedata: any = useSiteData()

const titleData = { name: t('login') }

useHead({
  title: titleData.name,
  link: [
    {
      hid: 'icon',
      rel: 'icon',
      type: 'image/x-icon',
      href: sitedata.value?.favicon ? sitedata.value?.favicon : null,
    },
  ],
})

const columns = [
  {
    name: 'username',
    label: `${t('username')}`,
    field: 'username',
    type: 'string',
    validate: [
      'required',
    ],
  },
  {
    name: 'password',
    label: `${t('password')}`,
    field: 'password',
    type: 'password',
    validate: [
      'required',
    ],
  },
]

const error = ref('')

const loading = ref(false)

async function onFormSubmit(payload: any) {
  loading.value = true

  const data: any = await $fetch('/api/login', {
    method: 'post',
    headers: { domain: host, locale: locale.value },
    body: payload,
  })

  loading.value = false

  if (data?.token) {
    toastAdd({ title: t('login_success') })

    await Promise.all([
      (error.value = ''),
      (token.value = data?.token ?? null),
      (authenticated.value = true),
      navigateTo(redirectUrl.value, { external: true }),
    ])
  }
  else {
    await Promise.all([
      (token.value = null),
      (authenticated.value = false),
    ])

    if (data?.err) {
      error.value = data?.err
    }
  }
}
</script>

<template>
  <div>
    <NuxtLayout :name="sitedata?.layout ?? 'default'">
      <div class="mx-auto max-w-screen-xl">
        <div class="container m-auto px-2 text-gray-600 md:px-4 xl:px-2">
          <ArticleTitle :data="titleData" />

          <div class="m-auto max-w-2xl px-3">
            <UAlert
              v-if="error"
              :close-button="{ icon: 'i-heroicons-x-mark-20-solid', color: 'gray', variant: 'link', padded: false }"
              icon="i-heroicons-x-circle-solid"
              color="red"
              variant="soft"
              :title="t(error)"
              :ui="{
                wrapper: 'my-5',
                title: 'text-lg mb-1',
                description: 'text-lg',
                icon: {
                  base: 'h-12 w-12',
                },
              }"
              @close="error = ''"
            />
          </div>

          <FormInput
            :columns="columns"
            :isemit="true"
            :islogin="true"
            :no-checkbox="true"
            :loading="loading"
            :submit-label="t('login')"
            class="max-w-2xl lg:shadow-lg lg:rounded-xl lg:border lg:border-gray-100 px-10"
            @save="onFormSubmit"
          />
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
